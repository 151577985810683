var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title": "机构分组新增",
        },
        on: {
          "head-save": function ($event) {
            return _vm.headSave()
          },
          "head-save-back": function ($event) {
            return _vm.headSave(true)
          },
          "head-cancel": _vm.headCancel,
        },
      }),
      _c(
        "div",
        {
          staticClass: "newZtFormBox",
          staticStyle: {
            "background-color": "white",
            "padding-top": "10px",
            "padding-right": "10px",
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "分组名称:", prop: "groupName" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "分组名称",
                              autocomplete: "off",
                            },
                            model: {
                              value: _vm.form.groupName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "groupName", $$v)
                              },
                              expression: "form.groupName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "分组编码:", prop: "groupCode" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "分组编码",
                              autocomplete: "off",
                            },
                            model: {
                              value: _vm.form.groupCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "groupCode", $$v)
                              },
                              expression: "form.groupCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "绑定单位:", prop: "deptIdList" } },
                        [
                          _c("avue-input-tree", {
                            attrs: {
                              multiple: "",
                              placeholder: "请填写绑定单位 ",
                              dic: _vm.deptTreeData,
                              props: _vm.inputTreeProps,
                            },
                            model: {
                              value: _vm.form.deptIdList,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "deptIdList", $$v)
                              },
                              expression: "form.deptIdList",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: { title: "绑定单位", visible: _vm.dialogFormVisible },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible = $event
                },
              },
            },
            [
              _c("el-tree", {
                ref: "tree",
                attrs: {
                  data: _vm.companyList,
                  "show-checkbox": "",
                  "default-expand-all": "",
                  "node-key": "id",
                  "highlight-current": "",
                  props: _vm.defaultProps,
                },
              }),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.SureBind } },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }